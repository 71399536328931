.color-picker {
  position: relative;
  padding: 10px 8px 0 0;
}

.color-picker .swatch {
  padding: 3px;
  background: #fff;
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
}

.color-picker .swatch .color {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
}

.color-picker .popover {
  position: absolute;
  z-index: 2;
}

.color-picker .cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
