.result-frame {
  outline: 1px solid #333;
}

.result-frame.mini {
  display: inline-block;
  margin: 20px auto;
}

.result-frame.full {
  overflow: visible;
}

.result-frame > div {
  background-color: white;
  border: 0;
  transform-origin: 0 0;
}

.result-frame.mini > div {
  width: 200%;
  height: 200%;
  transform: scale(0.5, 0.5);
  pointer-events: none;
}

.result-frame.full > div {
  width: 100%;
  height: 100%;
}
