@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply font-bold py-2 px-4 rounded;
}

.btn-blue {
  @apply bg-blue-500 text-white;
}

.btn-blue:hover {
  @apply bg-blue-700;
}

.btn[disabled] {
  @apply opacity-50 cursor-not-allowed;
}

.form-input {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.form-select {
  @apply appearance-none;
  -webkit-appearance: none;
  @apply text-black;
}

.results {
  display: grid;
  grid-gap: 40px 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
}

.variable-settings {
  @apply text-xl text-gray-500 rounded-lg rounded-b-none pb-2 pr-2 -mt-1 pt-1 pl-2 mr-2;
}

.variable-settings-active {
  @apply bg-gray-700;
}

button[type=button]:focus {
  outline: 0;
}
