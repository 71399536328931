.tab-list {
  @apply flex;
}

.tab {
  @apply inline-block py-2 px-4 text-gray-600 font-semibold w-full;
}

.tab:hover {
  @apply text-black;
}

.tab-active {
  @apply bg-gray-700 inline-block border-l border-t border-r rounded-t py-2 px-4 text-white font-semibold w-full;
}
