.action-button {
  @apply flex-1 border-blue-300 border m-2;
}

.action-button:hover {
  @apply bg-blue-300 text-blue-900;
}

.action-button:focus {
  outline: 0;
}
